import { useNavigate, useParams } from '@remix-run/react';
import { captureMessage } from '@sentry/remix';
import { addMinutes } from 'date-fns';
import { toDate } from 'date-fns-tz';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { type KeyedMutator } from 'swr';

import { useEventSchedulerAnalytics } from '../../analytics/eventScheduler';
import { apiService, type InvitedUser } from '../../services/api-service';
import { type Event } from '../../services/api-service/event.api';
import { RoleUtils } from '../../types';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { DeleteIcon } from '../icons/DeleteIcon';
import { Loading } from '../Loading';
import { PurchaseMethod } from '../OneTimePurchase/type';
import { useMyOrganization, useMyOrgId } from '../Organization';
import {
  organizersToSelectOptions,
  parseAnalyticsPropertiesFromOptions,
  parseUserIdsFromOptions,
} from '../Organization/OrganizerSelectGroup';
import { useUser } from '../UserContext';
import { EventEditor, type EventEditorData } from './Editor';
import { ErrorMessage } from './ErrorMessage';
import { PageLoading } from './PageLoading';
import { useEvent } from './useEvent';

function convert(event: Event): EventEditorData {
  const startDate = toDate(event.startAt);
  const endDate = toDate(event.endAt);
  const durationMs = Math.abs(endDate.getTime() - startDate.getTime());
  const durationMin = Math.floor(durationMs / 60000);

  return {
    gamePackId: event.gamePack.id,
    eventTime: {
      startDate,
      timezone: event.timezone,
    },
    durationMin,
    message: event.message,
    attendees: organizersToSelectOptions(event.attendees),

    orgId: event.organizer?.orgId ?? null,
    organizerUid: event.organizer?.uid ?? null,
    hostUid: event.hostUid,
    venueOption: event.venue
      ? { label: event.venue.name ?? event.venue.id, value: event.venue.id }
      : null,
    studioOption: event.studio
      ? { label: event.studio.name, value: event.studio.id }
      : null,
    producerUid: event.producerUid,
    organizer: event.organizer,
    type: event.type,
    prepMin: event.data?.prepMin,
    eventTitle: event.data?.eventTitle,
    eventFirstParagraph: event.data?.eventFirstParagraph,
    hostShoutOut: event.data?.hostShoutOut,
    orgName: event.data?.orgName,
    organizerName: event.data?.organizerName,
    organizerEmail: event.data?.organizerEmail,
    attendeeEmails: event.data?.attendeeEmails,
    sendLiveNotify: event.type === 'live' ? false : true,
  };
}

function DeleteConfirmationModal(): JSX.Element {
  const analytics = useEventSchedulerAnalytics();
  useEffectOnce(() => {
    analytics.trackDeleteConfirmationViewed();
  });

  return (
    <div className='text-white flex-col items-center justify-center py-2 px-4'>
      <ConfirmCancelModalText className='text-2xl font-medium'>
        Delete this event?
      </ConfirmCancelModalText>
      <div className='my-2 text-sms text-center'>
        When you delete an event we'll notify the attendees. This action cannot
        be undone.
      </div>
    </div>
  );
}

export function EventManager(props: {
  event: Event;
  mutate: KeyedMutator<Event>;
  withAdminHeader?: boolean;
  adminMode?: boolean;
  backTo?: string;
}): JSX.Element {
  const analytics = useEventSchedulerAnalytics();
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const [canceling, setCanceling] = useState(false);
  const navigate = useNavigate();
  const myOrg = useMyOrganization();
  const myOrgId = useMyOrgId();
  const me = useUser();

  const onSubmit = async (data: EventEditorData) => {
    if (!data.gamePackId) return;

    const { startDate, timezone } = data.eventTime;
    const endAt = data.durationMin
      ? addMinutes(startDate, data.durationMin)
      : null;

    let attendeeUids = parseUserIdsFromOptions(data.attendees);
    const invitedUsers: InvitedUser[] = [];
    for (const attendee of data.attendees) {
      if (attendee.kind === 'slack-user') {
        invitedUsers.push({
          email: attendee.user.email,
          fullName: attendee.user.fullName,
          exUserId: attendee.user.id,
        });
      }
      if (attendee.kind === undefined) {
        invitedUsers.push({
          email: attendee.value,
        });
      }
    }
    if (invitedUsers.length > 0) {
      if (myOrgId) {
        const resp = await apiService.organization.inviteOrganizers(myOrgId, {
          invitedUsers,
          webEndpoint: window.location.origin,
          sendEmail: true,
        });
        const set = new Set(attendeeUids);
        for (const o of resp.data.organizers) {
          set.add(o.uid);
        }
        attendeeUids = Array.from(set);
      } else {
        captureMessage(`create ond event with slack users but no orgId`, {
          extra: {
            uid: me.id,
          },
        });
      }
    }

    const req = {
      gamePackId: data.gamePackId,
      message: data.message,
      startAt: startDate.toISOString(),
      endAt: endAt?.toISOString() ?? null,
      timezone,
      attendees: attendeeUids,
      attendeeEmails: data.attendeeEmails,
      prepMin: data.prepMin,
      venueId: data.venueOption?.value,
      hostUid: data.hostUid,
      studioId: data.studioOption?.value,
      sendLiveNotify: data.sendLiveNotify,
      producerUid: data.producerUid,
      eventTitle: data.eventTitle,
      eventFirstParagraph: data.eventFirstParagraph,
      hostShoutOut: data.hostShoutOut,
      organizerName: data.organizerName,
    };

    analytics.trackFormSubmitted({
      ...req,
      message: undefined,
      hasMessage: data.message.length > 0,
      totalAttendees: data.attendees.length + 1,
      ...parseAnalyticsPropertiesFromOptions(data.attendees),
      durationMin: data.durationMin,
      purchaseMethod: myOrg?.purchase?.gamePackIds.includes(data.gamePackId)
        ? PurchaseMethod.OneTimePurchase
        : undefined,
    });

    const resp = await apiService.event.updateEvent(props.event.id, req);
    const updated = resp.data.event;
    await props.mutate(updated, { revalidate: false });

    navigate(props.backTo ?? `/events/${resp.data.event.id}`);
  };

  const onDelete = async () => {
    const response = await confirmCancel({
      kind: 'confirm-cancel',
      prompt: <DeleteConfirmationModal />,
      confirmBtnLabel: 'Delete',
      cancelBtnLabel: 'Cancel',
      autoFocus: 'cancel',
    });
    if (response.result !== 'confirmed') {
      analytics.trackDeleteConfirmationDismissed();
      return;
    }
    analytics.trackDeleteConfirmationConfirmed();
    setCanceling(true);
    try {
      await apiService.event.cancelEvent(props.event.id);
      await props.mutate();
    } finally {
      setCanceling(false);
    }
    if (props.backTo) {
      navigate(props.backTo);
    } else {
      navigate(-1);
    }
  };

  const onCancel = () => {
    analytics.trackFormDismissed();
    navigate(-1);
  };

  const maybeLabel =
    props.event.type !== 'live' ? null : (
      <div
        className={`
          px-3 py-1 w-max rounded-xl
          ${props.event.organizerUid ? 'bg-lp-green-001' : 'bg-tertiary'}
          text-white text-xs font-bold tracking-wide text-center`}
      >
        {props.event.organizerUid ? 'Subscriber' : 'Non-Subscriber'}
      </div>
    );

  const title =
    props.adminMode && props.event.type === 'live' ? (
      <div className='flex items-center gap-2'>
        <>Edit a Scheduled Live Game</>
        {maybeLabel}
      </div>
    ) : (
      <>{`Edit ${props.adminMode ? 'a' : 'Your '} Scheduled ${
        props.event.type === 'live' ? 'Live' : 'On Demand'
      } Game`}</>
    );

  return (
    <div className='relative w-full h-full'>
      {canceling && (
        <div className='fixed inset-0 bg-lp-black-001 flex items-center justify-center z-40'>
          <Loading text='' />
        </div>
      )}
      {props.withAdminHeader && (
        <div className='absolute top-0 left-0 right-0 bg-admin-red text-white flex justify-center items-center font-bold'>
          Note: this is an admin view!
        </div>
      )}
      <EventEditor
        context='edit'
        title={title}
        subtitle={
          props.event.type === 'live'
            ? ''
            : 'Update or delete your scheduled game. Any changes will be automatically emailed to your guests.'
        }
        initialData={convert(props.event)}
        eventType={props.event.type}
        onSubmit={onSubmit}
        onCancel={onCancel}
        extraFormFields={[
          <div
            key='delete-event'
            className='flex items-center gap-3 text-red-004'
          >
            <DeleteIcon className='fill-current w-5 h-5' />

            <div className='flex-1'>
              <button
                type='button'
                className='text-red-004'
                onClick={onDelete}
                disabled={canceling}
              >
                Delete Event
              </button>
            </div>
          </div>,
        ]}
        eventId={props.event.id}
      />
    </div>
  );
}

export function EventManage(props: {
  withAdminHeader: boolean;
  canceledMessage?: string;
  home?: string;
  className?: string;
}): JSX.Element | null {
  const { eventId } = useParams<'eventId'>();
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);
  const { data: event, error, isValidating, mutate } = useEvent(eventId);
  if (!eventId) return null;

  return (
    <div
      className={`flex-1  ${
        props.className !== undefined
          ? props.className
          : 'overflow-y-auto scrollbar'
      } h-full bg-game-library bg-w-full bg-no-repeat bg-top bg-local flex justify-center`}
    >
      {!event && isValidating ? (
        <PageLoading />
      ) : error || !event ? (
        <ErrorMessage
          title='Something Went Wrong'
          body='We had trouble loading this event. Please try again.'
        />
      ) : event.organizer?.uid !== user.id && !isAdmin ? (
        <ErrorMessage
          title='Forbidden'
          body='Only the event organizer may edit this event.'
        />
      ) : event.status === 'canceled' ? (
        <ErrorMessage
          title='Event Canceled'
          body={
            props.canceledMessage ??
            'This event was canceled. We notified your guests via email.'
          }
          home={props.home}
        />
      ) : (
        <div className='w-full h-full'>
          <EventManager
            event={event}
            mutate={mutate}
            withAdminHeader={
              props.withAdminHeader &&
              isAdmin &&
              event.organizer &&
              event.organizer.uid !== user.id
            }
            adminMode={isAdmin}
            backTo={props.home}
          />
        </div>
      )}
    </div>
  );
}
